import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Header } from '../components/layout/index.js';
import { Section, Container, Button } from '../components/common/index.js';

import {
  FormGroup,
  FormLabel,
  Input,
  TextArea,
  Optional,
  Select
} from '../components/layout/FormStyles';

const Small = styled.p`
  font-size: 0.8rem;
`;

const onFormSuccess = e => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'formSubmissionSuccess',
    formName: 'Career Form'
  });
};

const Career = ({ data }) => (
  <>
    <Helmet>
      <title>
        Careers in backpacker hostels with Base Backpackers Australia
      </title>
      <meta
        name="description"
        content="Work with some of the most experienced people in Austrlaia's youth adventure tousim industry. A career with Base backpackers opens a door to working in this exciting sector."
      />
    </Helmet>
    <Header
      backgroundImage={data.file.childImageSharp.fluid}
      pageTitle="Careers at Base Backpackers"
      tagline="Fund your adventures"
      // propertyName="Base Magnetic Island"
      // caption="Sunset Walking Tour"
    />

    <Section>
      <Container>
        <p>
          Are you a wanderlust traveller who wants to kick-start or create a
          foundation to build a career in travel?
        </p>
        <p>
          At Base we have a range of employment options from full-time or casual
          to the our unique Nomadic Live-in’ program that let’s you live the
          life the way you want!
        </p>
        <p>
          If you’ve got experience in sales, customer service, hospitality or
          house-keeping we want to hear from you!
        </p>
        <h2>JobSearch</h2>
        <p>
          Working for Base isn’t a real job it’s a lifestyle, we are on a
          mission to create extraordinary experiences for our customers and our
          tribe.
        </p>
        <p>Before you apply ask yourself</p>
        <p>Do I love having fun, travel and adventure?</p>
        <p>Do I work hard and play hard?</p>
        <p>
          If you said “YES” we want to hear from you, so match your skills &amp;
          experience with the right job and apply today!
        </p>
        <h2>Nomadic Live-in’</h2>
        <p>
          We’ve listened to our tribe of Aussie &amp; International staff
          members, and in response to their feedback we’ve created a program
          that supports the ultimate nomadic work and travel lifestyle.
        </p>
        <p>
          Live-In’ is a lifestyle choice a chance to join the Base community and
          engrain yourself in the social fabric of the hostel experience.
        </p>
        <p>
          We invite people with the right skills &amp; experience to work in
          advertised hostel jobs get paid, stay and eat at our onsite bars &amp;
          restaurants. You’ll live with your teammates, socialise and explore
          the idyllic city or town your working in with the rest of your tribe!
        </p>
        <p>
          You’ll be able to access our internal jobs board and apply for work in
          any location within the network of 26 hostels so you can continue to
          explore Australia &amp; New Zealand.
        </p>
        <p>
          You’ll have access to participate in staff events, get together and
          adhoc rewards for outstanding delivery of our mission to create
          extraordinary experiences for our customers.
        </p>
        <p>
          We know this isn’t for everyone but we offer it as a choice for our
          tribe to learn, explore and connect.
        </p>
        <p>
          Once you’ve been offered a chance to join the tribe ask your Manager
          if there are Nomadic Live-In beds available.
        </p>
        <p>Send us your CV and tell us why you want to join the Base Tribe!</p>

        <form
          name="Careers Form"
          method="POST"
          data-netlify="true"
          onSubmit={onFormSuccess}
          // action="https://getform.io/f/a01d6362-13bd-457e-83a8-b3ff96ec3133"
          // encType="multipart/form-data"
          // action="/success/"
        >
          <input type="hidden" name="form-name" value="Careers Form" />
          <div style={{ color: 'red', textAlign: 'right' }}>
            <Small>* Required field</Small>
          </div>
          <FormGroup>
            <FormLabel>Which loaction are you interested in?</FormLabel>
            <select id="property" name="property" autoFocus required>
              <option value="1" defaultValue />
              <option value="Sydney">Sydney</option>
              <option value="Melbourne">Melbourne</option>
              <option value="Brisbane">Brisbane</option>
              <option value="Airlie Beach">Airlie Beach</option>
              <option value="Magnetic Island">Magnetic Island</option>
              <option value="Auckland">Auckland</option>
              <option value="Bay of Islands">Bay of Islands</option>
              <option value="Rotorua">Rotorua</option>
              <option value="Taupo">Taupo</option>
              <option value="Wellington">Wellington</option>
              <option value="Queenstown">Queenstown</option>
            </select>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="name">
              Your Name:
              <span className="required">*</span>
            </FormLabel>
            <Input
              id="name"
              type="text"
              name="name"
              placeholder="Excited Bob"
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="email">
              Your Email:
              <span className="required">*</span>{' '}
            </FormLabel>
            <Input
              id="email"
              type="email"
              name="email"
              placeholder="bob@bringonthemadness.com"
              required
            />
            <Small>We won't share your email with anyone</Small>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="phone">
              Your Phone Number: <Optional>Optional</Optional>
            </FormLabel>
            <Input
              id="phone"
              type="phone"
              name="phone"
              placeholder="+61 420 420 420"
            />
          </FormGroup>

          <FormGroup>
            <FormLabel htmlFor="about">
              Tell us about yourself: <span className="required">*</span>
            </FormLabel>
            <TextArea
              id="about"
              name="about"
              placeholder="e.g What’s your name, where are you from and what locations would you be most excited to work in? "
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="why">
              Why do you stand out from the crowd?{' '}
              <span className="required">*</span>
            </FormLabel>
            <TextArea
              id="why"
              name="why"
              placeholder="I can tame a giraff by singing Lady Gaga's - Bad Romance"
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="cv">
              Upload your CV/Resume
              <span className="required">*</span>
            </FormLabel>
            <input
              type="file"
              name="cv"
              required
              style={{ display: 'block' }}
            />
          </FormGroup>

          <p style={{ display: 'none' }}>
            <label>
              Don’t fill this out if you're human:{' '}
              <input type="hidden" name="_gotcha" />
            </label>
          </p>
          <FormGroup>
            <Button primary type="submit">
              Send it!
            </Button>
          </FormGroup>
        </form>
      </Container>
    </Section>
  </>
);

export default Career;

export const query = graphql`
  query {
    file(relativePath: { eq: "1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
